
import {
  IonAvatar,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  popoverController
} from '@ionic/vue';
import { computed, defineComponent, ref } from 'vue';
import {
  chevronForwardOutline,
  codeWorkingOutline,
  hardwareChipOutline,
  lockClosedOutline,
  openOutline,
  personCircleOutline,
  rocketOutline,
  shieldHalfOutline
} from 'ionicons/icons';
import { useAuthStore } from '@/store/auth';
import { useRouter } from "vue-router";
import { goToOms } from '@hotwax/dxp-components'
import { isMaargLogin, isOmsWithMaarg } from '@/util';
import { translate } from '@/i18n';
import UserActionsPopover from '@/components/UserActionsPopover.vue'
import Image from "@/components/Image.vue";

export default defineComponent({
  name: 'Home',
  components: {
    Image,
    IonAvatar,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonPage
  },
  ionViewDidEnter() {
    // clearing the redirect URL to break the login and redirection flow
    // if the user navigates to the home page while login
    this.authStore.setRedirectUrl('')
  },
  methods: {
    login() {
      // hydrate (pinia-plugin-persistedstate) will sync the app state with the
      // localStorage state for avoiding the case when two launchpad tabs are opened
      // and the user logs in through one and tries to login again from the next tab
      // $hydate will resync the state and hence, update the app UI
      this.authStore.$hydrate({ runHooks: false })
      // push to login only if user is not logged in (after state hydration)
      if (!this.authStore.isAuthenticated) {
        this.router.push('/login')
      }
    },
    async logout() {
      this.authStore.$hydrate({ runHooks: false })
      // hydrate and logout only if user is logged in (authenticated)
      if (this.authStore.isAuthenticated) {
        await this.authStore.logout()
      }
    },
    generateAppLink(app: any, appEnvironment = '') {
      const oms = isMaargLogin(app.handle, appEnvironment) ? this.authStore.getMaargOms : this.authStore.getOMS;
      window.location.href = this.scheme + app.handle + appEnvironment + this.domain + (this.authStore.isAuthenticated ? `/login?oms=${oms.startsWith('http') ? isMaargLogin(app.handle, appEnvironment) ? oms : oms.includes('/api') ? oms : `${oms}/api/` : oms}&token=${this.authStore.token.value}&expirationTime=${this.authStore.token.expiration}${isMaargLogin(app.handle, appEnvironment) ? '&omsRedirectionUrl=' + this.authStore.getOMS : isOmsWithMaarg(app.handle, appEnvironment) ? '&omsRedirectionUrl=' + this.authStore.getMaargOms : ''}` : '')
    },
    async openUserActionsPopover(event: any) {
      const userActionsPopover = await popoverController.create({
        component: UserActionsPopover,
        event,
        showBackdrop: false,
      });

      userActionsPopover.present();
    },
    startDrag(event: any, app: any) {
      event.dataTransfer.dropEffect = "move"
      event.dataTransfer.effectAllowed = "move"
      event.dataTransfer.setData("appId", app.handle)
      event.dataTransfer.setData("appSequenceNum", app.sequenceNum)
    },
    onDrop(event: any, category: string, index?: number) {
      const appId = event.dataTransfer.getData("appId")
      const appSequenceNum = event.dataTransfer.getData("appSequenceNum")
      const app: any = this.appInfo.find((app: any) => app.handle === appId)
      const appCategory = app["type"]

      // If the dragged app's type is unchanged, then don't do anything
      if(category && app["type"] !== category) {

        app["type"] = category

        // Below code handles the case to update the sequence inside category from where the app is dragged
        // Filter apps of the current category in which the dragged app is dropped
        const apps = this.appInfo.filter((appInformation: any) => appInformation.type === appCategory);

        // For each app in the current category, if the sequenceNum is >= dropped position
        // then update the app sequenceNum by 1, to shift the app
        apps.map((app: any) => {
          if(app.sequenceNum >= appSequenceNum) {
            app["sequenceNum"]--
          }
        })
      }

      // Update the sequence of the app in the list
      if((index || index === 0) && (appSequenceNum !== index || appCategory !== category)) {
        // Filter apps of the current category in which the dragged app is dropped
        const apps = this.appInfo.filter((app: any) => app.type === category);

        // For each app in the current category, if the sequenceNum is >= dropped position
        // then update the app sequenceNum by 1, to shift the app
        apps.map((app: any) => {
          if(appSequenceNum >= index) {
            if(app.sequenceNum >= index && (app.sequenceNum <= appSequenceNum || appCategory !== category)) {
              app["sequenceNum"]++
            }
          } else {
            if(app.sequenceNum <= index && app.sequenceNum >= appSequenceNum) {
              app["sequenceNum"]--
            }
          }
        })

        // Update the sequence of the dragged app with the original dropped position
        app["sequenceNum"] = index
      }
    },
    onDragOver(event: any) {
      if (event.preventDefault) {
        event.preventDefault();
      }

      event.dataTransfer.dropEffect = "move";

      return false;
    }
  },
  setup() {
    const authStore = useAuthStore();
    const router = useRouter();

    // Defined this object to manage the categories sequence on drag and drop
    const categories = ref({
      "Orders": [],
      "Workflow": [],
      "Inventory": [],
      "Administration": []
    })

    const appInfo = ref([{
      handle: 'bopis',
      name: 'BOPIS',
      resource: require('../assets/images/BOPIS.svg'),
      type: 'Orders',
      sequenceNum: 0
    }, {
      handle: 'fulfillment',
      name: 'Fulfillment',
      resource: require('../assets/images/Fulfillment.svg'),
      type: 'Orders',
      sequenceNum: 1
    }, {
      handle: 'preorder',
      name: 'Pre-Orders',
      resource: require('../assets/images/PreOrder.svg'),
      type: 'Orders',
      sequenceNum: 2
    },  {
      handle: 'atp',
      name: 'Available to Promise',
      resource: require('../assets/images/Atp.svg'),
      type: 'Workflow',
      sequenceNum: 0
    }, {
      handle: 'job-manager',
      name: 'Job Manager',
      resource: require('../assets/images/Job.svg'),
      type: 'Workflow',
      sequenceNum: 1
    }, {
      handle: 'receiving',
      name: 'Receiving',
      resource: require('../assets/images/Receiving.svg'),
      type: 'Inventory',
      sequenceNum: 0
    }, {
      handle: 'inventorycount',
      name: 'Cycle Count',
      resource: require('../assets/images/CycleCount.svg'),
      type: 'Inventory',
      sequenceNum: 1
    }, {
      handle: 'picking',
      name: 'Picking',
      resource: require('../assets/images/Picking.svg'),
      type: 'Inventory',
      sequenceNum: 2
    }, {
      handle: 'transfers',
      name: 'Transfers',
      resource: require('../assets/images/Transfers.svg'),
      type: 'Inventory',
      sequenceNum: 3
    }, {
      handle: 'import',
      name: 'Import',
      resource: require('../assets/images/Import.svg'),
      type: 'Administration',
      sequenceNum: 0
    }, {
      handle: 'users',
      name: 'Users',
      resource: require('../assets/images/UserManagement.svg'),
      type: 'Administration',
      sequenceNum: 1
    }, {
      handle: 'facilities',
      name: 'Facilities',
      resource: require('../assets/images/Facilities.svg'),
      type: 'Administration',
      sequenceNum: 2
    }, {
      handle: 'order-routing',
      name: 'Order Routing',
      resource: require('../assets/images/OrderRouting.svg'),
      type: 'Workflow',
      sequenceNum: 2
    }, {
      handle: 'company',
      name: 'Company',
      resource: require('../assets/images/Company.svg'),
      type: 'Administration',
      sequenceNum: 3
    }])

    const sortedApps = () => appInfo.value.sort((a, b) => a.sequenceNum - b.sequenceNum)

    const appCategory = computed(() => sortedApps().reduce((obj: any, app: any) => {
      if (obj[app.type]) {
        obj[app.type].push(app)
      } else {
        obj[app.type] = [app]
      }

      return obj
    }, JSON.parse(JSON.stringify(categories.value))))

    const scheme = ref('https://')
    const domain = ref('.hotwax.io')
    const uatHandle = ref('-uat')
    const devHandle = ref('-dev')

    return {
      authStore,
      appCategory,
      chevronForwardOutline,
      codeWorkingOutline,
      devHandle,
      domain,
      goToOms,
      isMaargLogin,
      isOmsWithMaarg,
      lockClosedOutline,
      hardwareChipOutline,
      openOutline,
      personCircleOutline,
      rocketOutline,
      router,
      scheme,
      shieldHalfOutline,
      translate,
      uatHandle,
      appInfo
    }
  }
});

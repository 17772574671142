import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "title" }
const _hoisted_2 = ["onDrop"]
const _hoisted_3 = { class: "app-icon ion-padding" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_Image = _resolveComponent("Image")!
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("header", null, [
            _createElementVNode("h1", _hoisted_1, [
              _createTextVNode(_toDisplayString(_ctx.$t('Launch Pad')) + " ", 1),
              _createVNode(_component_ion_icon, {
                color: "danger",
                icon: _ctx.rocketOutline
              }, null, 8, ["icon"])
            ]),
            (_ctx.authStore.isAuthenticated)
              ? (_openBlock(), _createBlock(_component_ion_card, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_list, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, {
                          lines: "full",
                          button: "",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openUserActionsPopover($event)))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_avatar, { slot: "start" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Image, {
                                  src: _ctx.authStore.current?.partyImageUrl
                                }, null, 8, ["src"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_label, { class: "ion-text-nowrap" }, {
                              default: _withCtx(() => [
                                _createElementVNode("h2", null, _toDisplayString(_ctx.authStore.current?.partyName ? _ctx.authStore.current?.partyName : _ctx.authStore.current.userLoginId), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_icon, {
                              slot: "end",
                              icon: _ctx.chevronForwardOutline,
                              class: "ion-margin-start"
                            }, null, 8, ["icon"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_item, {
                          lines: "none",
                          button: "",
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToOms(_ctx.authStore.token.value, _ctx.authStore.getOMS)))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, {
                              slot: "start",
                              icon: _ctx.hardwareChipOutline
                            }, null, 8, ["icon"]),
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createElementVNode("h2", null, _toDisplayString(_ctx.authStore.getOMS), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_icon, {
                              slot: "end",
                              icon: _ctx.openOutline,
                              class: "ion-margin-start"
                            }, null, 8, ["icon"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_ion_button, {
                  key: 1,
                  fill: "outline",
                  color: "danger",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.router.push('/login')))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "start",
                      icon: _ctx.personCircleOutline
                    }, null, 8, ["icon"]),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('Login')), 1)
                  ]),
                  _: 1
                }))
          ]),
          _createElementVNode("main", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.appCategory), (category) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "type",
                key: category
              }, [
                (_ctx.appCategory[category]?.length)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createElementVNode("h3", null, _toDisplayString(category), 1),
                      _createElementVNode("div", {
                        class: "apps",
                        onDrop: ($event: any) => (_ctx.onDrop($event, category)),
                        onDragover: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"])),
                        onDragenter: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"]))
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appCategory[category], (app) => {
                          return (_openBlock(), _createBlock(_component_ion_card, {
                            button: "",
                            class: "app",
                            key: app.handle,
                            disabled: _ctx.authStore.isAuthenticated && _ctx.isMaargLogin(app.handle) && !_ctx.authStore.getMaargOms,
                            draggable: "",
                            onDragstart: ($event: any) => (_ctx.startDrag($event, app)),
                            onDrop: _withModifiers(($event: any) => (_ctx.onDrop($event, category, app.sequenceNum)), ["stop"])
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_3, [
                                _createElementVNode("img", {
                                  src: app.resource
                                }, null, 8, _hoisted_4)
                              ]),
                              _createVNode(_component_ion_card_header, { class: "app-content" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_card_title, { color: "text-medium" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(app.name), 1)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  (_ctx.authStore.isAuthenticated && _ctx.isMaargLogin(app.handle) && !_ctx.authStore.getMaargOms)
                                    ? (_openBlock(), _createBlock(_component_ion_badge, {
                                        key: 0,
                                        class: "ion-margin",
                                        color: "medium"
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.translate("Not configured")), 1)
                                        ]),
                                        _: 1
                                      }))
                                    : (_openBlock(), _createBlock(_component_ion_buttons, {
                                        key: 1,
                                        class: "app-links"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_button, {
                                            color: "medium",
                                            disabled: _ctx.authStore.isAuthenticated && _ctx.isMaargLogin(app.handle, _ctx.devHandle) && !_ctx.authStore.getMaargOms,
                                            onClick: _withModifiers(($event: any) => (_ctx.generateAppLink(app, _ctx.devHandle)), ["stop"])
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_ion_icon, {
                                                slot: "icon-only",
                                                icon: _ctx.codeWorkingOutline
                                              }, null, 8, ["icon"])
                                            ]),
                                            _: 2
                                          }, 1032, ["disabled", "onClick"]),
                                          _createVNode(_component_ion_button, {
                                            color: "medium",
                                            disabled: _ctx.authStore.isAuthenticated && _ctx.isMaargLogin(app.handle, _ctx.uatHandle) && !_ctx.authStore.getMaargOms,
                                            onClick: _withModifiers(($event: any) => (_ctx.generateAppLink(app, _ctx.uatHandle)), ["stop"])
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_ion_icon, {
                                                slot: "icon-only",
                                                icon: _ctx.shieldHalfOutline
                                              }, null, 8, ["icon"])
                                            ]),
                                            _: 2
                                          }, 1032, ["disabled", "onClick"])
                                        ]),
                                        _: 2
                                      }, 1024))
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1032, ["disabled", "onDragstart", "onDrop"]))
                        }), 128))
                      ], 40, _hoisted_2)
                    ], 64))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}